<template>
  <div class="home">
    <div class="fix-icons">
      <div class="home-icon-holder" v-for="(icon) in icons" :key="icon.id" >
        <a :href="`${icon.link}`" target="_blank">
          <v-icon medium color="ploBlue">{{icon.icon}}</v-icon>
        </a>
      </div>
    </div>
    <div v-on:click="updateDrawer" style="cursor: pointer">
    <VueSlickCarousel v-bind="settings" class="desktop">
      <div class="slide-img-holder" v-for="(slide) in slides" :key="slide.id">
        <img class="slide-img" :src="require(`../assets/${slide.slideImg}`)" :alt="`${slide.title}`" />
        <div class="overlay">
          <div class="overlay-text-holder">
            <div class="overlay-text"><span>{{slide.title}}</span></div>
            <div class="overlay-desc">
              <span v-html="slide.text"></span>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
    <div class="mobile">
      <div class="mobile-slide" :style="{'background': 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(' + require('../assets/' + mobileSlide.slides[Math.floor(Math.random() * mobileSlide.slides.length)].img+'') + ')  no-repeat center center', 'background-size': 'cover'}">
        <div class="overlay">
          <div class="overlay-text-holder">
            <div class="overlay-text newfont"><span v-html="mobileSlide.title"></span></div>
            <div class="overlay-desc">
              <span v-html="mobileSlide.text"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Home",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnFocus: true,
        pauseOnHover: true,
      },
      // drawz: true,
      slides:[
        {
          id:1,
          slideImg:'ploutus-holdings.webp',
          title:'Ploutus Holdings',
          text:'<span class="white--text">We are a global Infrastructure Development and Finance company.  We work with clients worldwide in various industries and in diverse capacities to design, construct, and maintain their capital and infrastructure projects.</span>',
        },
        {
          id:2,
          slideImg:'connecting-the-world.webp',
          title:'Helping You Connect With The World',
          text:'<span class="white--text">We lay down strong foundations for a better tomorrow, providing profitable solutions to the toughest challenges. Our work is focused on creating collaborative partnerships and providing turnkey solutions to the governments that we work with.</span>',
        },
        {
          id:3,
          slideImg:'global.webp',
          title:'',
          text:'<ul class="points-head"> <li>&nbsp; 4 &nbsp; Continents</li> <li>&nbsp; 12 Countries</li> <li>&nbsp; 11 Sectors</li> </ul>',
        },
        {
          id:4,
          slideImg:'what-we-do.webp',
          title:'What We Do',
          text:'<span class="white--text">Ploutus Holdings LLC is a dynamic and multifaceted international organization with a plethora of services and years of experience. We value commitment, quality and sustainability in all of our agreements and projects.</span>',
        },
        {
          id:5,
          slideImg:'creating-value.webp',
          title:'Create Value Through Business Synergies',
          text:'<span class="white--text">We have found success and created value around the world<br>by bringing different people together for a common purpose.</span>',
        },
        {
          id:6,
          slideImg:'real-value.webp',
          title:'Provide Real Value',
          text:'<span class="white--text">We consistently provide successful results, by identifying potential problems and seeking out the most cost-effective solutions to mitigate them for your success.</span>',
        },
        {
          id:7,
          slideImg:'partnership.webp',
          title:'Build Strong Partnerships',
          text:'<span class="white--text">We focus on building relationships that foster loyalty and<br>value with all our clients, customers and stakeholders.</span>',
        },
        {
          id:8,
          slideImg:'business-sector.webp',
          title:'Business Sectors',
          text:'<span class="white--text">Ploutus Holdings LLC has many different subsidiaries associated with it, with projects including contracting services, telecommunications services, business IT solutions for government and commercial clients, value added tea distribution, real estate development, renewable power generation, health care projects and many more.</span>',
        },
        {
          id:9,
          slideImg:'csr.webp',
          title:'Corporate Social Responsibility',
          text:'<span class="white--text">At Ploutus Holdings LLC, we believe in creating a sustainable change in the communities that we operate in. Our CSR projects include children’s education and nutrition, women empowerment, clean water supply, construction of low-cost housing, health facilities for the disabled and elderly and much more.</span>',
        },
      ],
      mobileSlide:{
        id:1,
        slideImg:'global.jpg',
        title:'Create Value Through <br> Business Synergies',
        text:'<ul class="points-head"> <li>&nbsp; 4 Continents</li> <li>12 Countries</li> <li>11 Sectors</li> </ul>',
        slides:[
          {id:1, img:'s-mob-1.webp'},
          {id:2, img:'s-mob-2.webp'},
          {id:3, img:'s-mob-3.webp'},
          {id:4, img:'s-mob-4.webp'},
          {id:5, img:'s-mob-5.webp'},
          {id:6, img:'s-mob-6.webp'},
          {id:7, img:'s-mob-7.webp'},
          {id:8, img:'s-mob-8.webp'},
          {id:9, img:'s-mob-9.webp'},
        ]
      },
      icons: [
        {
          id:1,
          link: 'tel:+12407313911',
          icon: 'mdi-phone',
        },
        {
          id:2,
          link: 'mailto:info@ploutus.com',
          icon: 'mdi-email-outline',
        },
        {
          id:3,
          link: 'https://www.facebook.com/ploutusholdings',
          icon: 'mdi-facebook',
        },
        {
          id:4,
          link: 'https://twitter.com/ploutus_global',
          icon: 'mdi-twitter',
        },
        {
          id:5,
          link: 'https://www.linkedin.com/company/ploutus-holdings',
          icon: 'mdi-linkedin',
        },
        {
          id:6,
          link: 'https://instagram.com/ploutusholdings?utm_medium=copy_link',
          icon: 'mdi-instagram',
        },
      ]
    };
  },
  methods:{
    updateDrawer:function(){
      this.$emit('updateDrawer', true)
    }
  }
};
</script>
<style>
/* @import url('https://fonts.googleapis.com/css2?family=Quattrocento&display=swap');

.newfont{
  font-family: 'Quattrocento', serif;
} */

.home{
  position: relative;
}

.fix-icons{
  position: fixed;
  right: 30px;
  top: 35%;
  z-index: 5;
}

.home-icon-holder{
  padding: 5px;
  background: rgba(250, 182, 0, 0.7);
  border: 1px solid #213880;
  margin-bottom: 15px;
}

.home-icon-holder a{
  text-decoration: none;
}

.slide-img-holder {
  position: relative;
  width: 100vw;
  height: calc(100vh - 69px);
}

.slide-img {
  display: block;
  max-width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 575px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
}

.overlay-text-holder {
  top: 5%;
  position: relative;
  width: 100%;

}

.overlay-text {
  color: #fff;
  font-size: 32px;
  width: 100%;
  padding-left: 20px;
  font-weight: 600;
}

.overlay-desc {
  margin: 40px 0 5px 20px;
  font-size: 20px;
  color: #fff;
  text-align: justify;
}

.overlay-text span, .overlay-desc span{
  /*text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black;*/
  backdrop-filter: blur(5px);
}

.points-head {
  color: #fff;
  font-size: 44px;
  list-style: none;
  font-weight: 600;
}

.mobile{
    display: none;
}

@media screen and (max-width: 991px) {
  .overlay-text-holder {
    top: 5%;
  }

  .overlay-desc {
    padding: 5px 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {

  .mobile-slide{
    height: calc(100vh - 60px);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 40px;
  }

  .mobile{
    display: block;
  }

  .desktop{
      display: none !important;
  }

}

@media screen and (max-width: 600px) {

  .v-application ol, .v-application ul {
    padding-left: 0 !important;
  }

  .overlay-text-holder {
    top: 0;
  }

  .overlay-text {
    font-size: 28px;
    padding-left: 10px;
    font-weight: 600;
    text-shadow: 1px 1px 0px #000;
    text-align: center;
  }

  .overlay-desc {
    padding: 25vh 10px 10px;
    font-size: 14px;
    font-weight: 600;
    text-shadow: 1px 1px 0px #000;
  }

  .points-head {
    font-size:36px;
  }
}
</style>